/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function Seo({ description, tags, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						description
						title
					}
				}
			}
		`
	);

	const metaDescription = description || '';

	return (
		<Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
			<title>{title}</title>
			<meta content={metaDescription} name="description" />
			<meta content={tags} name="keywords" />
		</Helmet>
	);
}

Seo.defaultProps = {
	description: '',
};

Seo.propTypes = {
	description: PropTypes.string,
	tags: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default Seo;
