import React from 'react';

const Footer = () => {
	return (
		<footer>
		</footer>
	);
};

export default Footer;
